<template>
  <div>
    <form-layout sub-text="Forgot Password">
      <div class="form-group">
        <label class="form-label">Email</label>
        <input
          type="email"
          :class="['form-control', { 'form-control--error': $v.params.email.$anyError }]"
          v-model="$v.params.email.$model"
          @keyup.enter="onResetPassword"
        />
        <p v-if="$v.params.email.$anyError" class="form-error-text">
          <span v-if="!$v.params.email.required">Email can't be blank</span>
          <span v-if="!$v.params.email.email">Email is invalid</span>
        </p>
      </div>

      <div class="mt-24">
        <button class="btn btn--primary btn--full" @click="onResetPassword">Submit</button>
      </div>

      <div class="text-center mt-24">
        <p>
          Back to
          <router-link class="link link--primary" to="/login"> Log in </router-link>
        </p>
      </div>
    </form-layout>

    <confirm-dialog cancel-button-text="Close" :open.sync="openConfirmDialog">
      <template v-slot:content>
        <p class="text-center">
          A reset password link has been sent to your email address.<br />
          Please check your email.
        </p>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import forgotPasswordFormValidator from './validations/forgot-password-form'
const FormLayout = () => import('@/layouts/FormLayout')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      params: {
        email: '',
        resend: true
      },
      openConfirmDialog: false
    }
  },

  mixins: [forgotPasswordFormValidator],

  components: {
    FormLayout,
    ConfirmDialog
  },

  methods: {
    ...mapActions('auth', ['resetPassword']),

    onResetPassword() {
      this.$v.$touch()
      if (this.isFormValid) {
        this.resetPassword(this.params).then(() => {
          this.openConfirmDialog = true
        })
      }
    }
  }
}
</script>
